import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { graphql } from 'gatsby';
import first from 'lodash/first';

import SEO from '../components/seo';
import Layout from '../components/layout';
import PageBody from '../components/PageBody';
import PriceFeeds from '../components/PriceFeeds';
import MainCol from '../components/PageBody/MainCol';
import JumbotronBlock from '../components/JumbotronBlock';
import SideRailsMenu from '../components/SideRailsMenu';
import ColumnWrapper from '../components/PageBody/styled/ColumnWrapper';

const propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
};

const defaultProps = {
  data: null,
  errors: [],
};

function ProductsLandingPage({ data, errors }) {
  const pageData = first(data.prismic.allProducts_landing_pages.edges);
  const sidebarData = { products: data.prismic.allProducts.edges };

  if (!pageData) {
    return null;
  }

  const {
    seo_title,
    seo_meta_description,
    page_title,
    header_banner,
    page_description,
    _meta,
    body,
  } = pageData.node;
  const pageTitle = page_title ? page_title[0].text : '';
  const pageDescription =
    page_description && page_description.length !== 0
      ? page_description[0].text
      : '';

  return (
    <Layout>
      <SEO
        title={seo_title || pageName}
        description={seo_meta_description || pageDescription}
        lang={_meta}
      />
      <JumbotronBlock
        title={pageTitle}
        url={header_banner && header_banner.url}
      />
      <Row>
        <SideRailsMenu title={pageTitle} data={sidebarData} />
        <MainCol lg={7} md={12}>
          <ColumnWrapper>
            {pageDescription && <p>{pageDescription}</p>}
            {body && <PageBody body={body} />}
          </ColumnWrapper>
        </MainCol>
        <PriceFeeds />
      </Row>
    </Layout>
  );
}

export const query = graphql`
  query ProductsLandingPageQuery($uid: String) {
    prismic {
      allProducts_landing_pages(uid: $uid) {
        edges {
          node {
            _meta {
              lang
            }
            seo_title
            seo_meta_description
            page_title
            header_banner
            body {
              ... on PRISMIC_Products_landing_pageBodyTwo_columns_video {
                type
                primary {
                  embedded_video
                }
                fields {
                  text_block
                  title
                  additional_text
                }
              }
              ... on PRISMIC_Products_landing_pageBodyText_block {
                type
                primary {
                  text_block_header
                  tooltip
                }
                fields {
                  text_block_content
                }
              }
              ... on PRISMIC_Products_landing_pageBodyTwo_columns {
                type
                fields {
                  text_block
                  title
                  image
                }
              }
              ... on PRISMIC_Products_landing_pageBodyTwo_columns_image {
                type
                fields {
                  image
                  title
                  text_block
                  additional_text
                }
                label
              }
              ... on PRISMIC_Products_landing_pageBodyLimited_time_offer {
                type
                label
                primary {
                  offer_list
                  text_block
                  background_image
                  offer_type
                  offer_button {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                }
              }
              ... on PRISMIC_Products_landing_pageBodyTable__10_cols_ {
                type
                primary {
                  table_title
                  table_description
                  col1
                  col2
                  col3
                  col4
                  col5
                  col6
                  col7
                  col8
                  col9
                  col10
                }
                fields {
                  col1_data
                  col2_data
                  col3_data
                  col4_data
                  col5_data
                  col6_data
                  col7_data
                  col8_data
                  col9_data
                  col10_data
                }
              }
            }
          }
        }
      }
      allProducts {
        edges {
          node {
            title
            dxfeed_symbol
            futures_symbol
            index_symbol
            _meta {
              id
              uid
            }
          }
        }
      }
    }
  }
`;

ProductsLandingPage.propTypes = propTypes;
ProductsLandingPage.defaultProps = defaultProps;

export default ProductsLandingPage;
